import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

/*
const links = [
  {
    text: "CakePHP Contributor",
    url: "https://github.com/cakephp",
  },
  {
    text: "KeepassX Japanese Translator (0.4.2, 0.4.3)",
    url: "https://www.keepassx.org",
  },
  {
    text: "PhpStormとPHPUnitを連携してユニットテスト作成を楽にする (第133回 PHP勉強会＠東京)",
    url: "https://tenkoma.hatenablog.com/entry/2018/12/27/010000",
  },
  {
    text: "ユニットテストを書きやすくするためにテストスイートを拡張する (2018/06/15 【非公式】PHPカンファレンス福岡2018前夜祭リジェクトコン)",
    url: "https://speakerdeck.com/tenkoma_/extend-test-suite-to-make-unit-test-easier-to-write",
  },
  {
    text: "GitLab CI + Review Apps で簡易レビュー環境 (2017/05/22 PHP BLT #7)",
    url: "https://tenkoma.hatenablog.com/entry/2017/05/24/014330",
  },
  {
    text: "CakePHP プロジェクトにプルリクを送り続けた僕は (2017/3/29 第112回PHP勉強会@東京 Lightning Talk)",
    url: "https://tenkoma.hatenablog.com/entry/2017/03/30/002642",
  },
  {
    text: "一人で使う／チームで活用するWebStorm (2016/5/24 第14回HTML5ビギナーズ勉強会)",
    url: "https://speakerdeck.com/tenkoma_/timutehuo-yong-suru-webstorm",
  },
  {
    text: "CakePHPでデバッグレベルを簡単に切り替えたい！ (2010/10/3 PHP Matsuri Hackathon成果発表)",
    url: "http://tenkoma.hatenablog.com/entry/20101017/1287300088",
  },
]
 */

const moreLinks = [
  { text: "GitHub", url: "https://github.com/tenkoma/" },
  { text: "Twitter", url: "https://twitter.com/tenkoma" },
  { text: "Blog", url: "https://tenkoma.hatenablog.com/" },
]

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const IndexPage = () => (
  <Layout>
    <div className={styles.textCenter}>
      <StaticImage
        src="https://avatars1.githubusercontent.com/u/16202?v=3"
        loading="eager"
        width={64}
        alt="tenkoma's logo"
        style={{ marginBottom: `var(--space-3)` }}
      />
      <h1>tenkoma</h1>
      <p className={styles.intro}>tenkoma(Koji Tanaka) is a software engineer based in Tokyo, Japan, born in 1981.</p>
    </div>
    <p>
      {moreLinks.map((link, i) => (
        <React.Fragment key={link.url}>
          <a href={`${link.url}${utmParameters}`}>{link.text}</a>
          {i !== moreLinks.length - 1 && <> · </>}
        </React.Fragment>
      ))}
    </p>
  </Layout>
)

/*
<ul className={styles.list}>
  {links.map(link => (
    <li key={link.url} className={styles.listItem}>
      <a
        className={styles.listItemLink}
        href={`${link.url}${utmParameters}`}
      >
        {link.text} ↗
      </a>
      <p className={styles.listItemDescription}>{link.description}</p>
    </li>
  ))}
</ul>
*/

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
